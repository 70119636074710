<template>
  <div class="mb-2">
    <VCard class="w-100 " elevation="2">
      <VCardTitle class="py-0 pl-1">
        <VRow
          class="align-center"
          no-gutters
          justify="center"
          justify-xl="space-between"
          justify-lg="space-between"
          justify-sm="center"
          justify-md="space-between"
          :class="{ 'flex-column-reverse ': $vuetify.breakpoint.smAndDown }"
        >
          <VCol cols="12" lg="5" md="5" sm="12" class="d-flex align-center">
            <VCol cols="auto">
              <span>
                {{ $t("catalog.catalogTitle") }}
              </span>
            </VCol>
            <VTextField
              dense
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('form.search')"
              v-model="searchString"
              clearable
              hide-details
              solo
              :loader-height="3"
              class="ml-2"
              @input="onSearch"
              :loading="isSearchLoading"
              @keypress="validationForTitle($event)"
              @paste="validationForTitle($event)"
            />
          </VCol>
          <v-spacer />
          <VCol cols="12" lg="2" md="2" sm="12" class="ml-auto text-right">
            <VBtn
              block
              depressed
              class="success-bg text-white text-transform-none br-10"
              @click="
                editDialog = true;
                typeDialog = 'add';
              "
            >
              <VIcon left>mdi-plus</VIcon>
             {{ $t('form.add') }}
            </VBtn>
          </VCol>
          <VCol cols="12" md="auto" class="text-right ml-3">
            <v-btn-toggle v-model="viewType" @change="onChangeViewType" mandatory dense>
              <VBtn>
                <VIcon color="#757575">mdi-menu</VIcon>
              </VBtn>
              <VBtn>
                <card-list-icon />
              </VBtn>
            </v-btn-toggle>
          </VCol>
        </VRow>
      </VCardTitle>
      <VDivider class="mt-1" />
      <chip-tab
        v-if="descriptions.length"
        class="ml-2"
        :items="chipTabsItems"
        @onClickChipTab="onClickChipTab"
        :loadingChips="loadingChips || Boolean(searchString)"
        :resetChip="Boolean(searchString) || isResetChip"
      />
    </VCard>
    <VSlideXTransition mode="out-in" >
      <VRow
        no-gutters
        justify="start"
        class="mr-0 pb-3"
        v-if="!isSearchLoading && descriptions.length"
        v-show="viewType == 1"
      >
        <VCol
          cols="12"
          md="3"
          sm="6"
          v-for="item in descriptions"
          :key="item.uuid"
          class="py-0 my-0 "
        >
          <VCol class="mx-0 px-2 pb-0">
            <DescriptionItem
              :item="item"
              @delete="onDeleteDescription(item.uuid)"
              @toggle-row="activeCard"
              :activeCardID="activeCardID"
            />
          </VCol>
        </VCol>
      </VRow>
      <div
        v-if="!isSearchLoading && !descriptions.length"
        class="w-100 d-flex align-center justify-content-center mt-2"
      >
        <h4> {{ $t("table.sorry_no_items_in_catalog") }} </h4>
      </div>
    </VSlideXTransition>
    <VSlideXReverseTransition mode="out-in">
      <VRow no-gutters align="center" class="mt-1" justify="space-between" v-if="!isSearchLoading && descriptions.length" v-show="viewType == 0">
        <VCol cols="12" :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''">
          <VCard class="mb-1 pt-2 pb-1">
            <v-simple-table dense class="my-table payment-row w-100 pa-2">
              <thead>
                <tr>
                  <th class="text-center xs"><VIcon dense>mdi-cogs</VIcon></th>
                  <th class="text-center x-sm">
                    <VIcon dense>mdi-tooltip-image</VIcon>
                  </th>
                  <th class="lg">{{$t('form.title')}}</th>
                  <th>{{$t('table.amount')}}</th>
                  <th>{{$t('products.capacity')}} ({{ $t('products.meter') }}<sup>3</sup>)</th>
                  <th>{{$t('table.barCode')}}</th>
                  <th>{{$t('table.price') }} ({{$t('paymentPlan.uah') }} ) </th>
                </tr>
              </thead>
              <tbody>
                <description-row
                  v-for="item in descriptions" :key="item.uuid"
                  :item="item"
                  @toggle-row="activeCard"
                  :activeCardID="activeCardID"
                />
              </tbody>
            </v-simple-table>
            <div
              v-if="!isSearchLoading && !descriptions.length"
              class="w-100 d-flex align-center justify-content-center"
              style="height:20vh"
            >
              {{ $t("table.noData") }}
            </div>
            <div v-if="isLoadingFilter && !isSearchLoading" class="w-100 text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <div
              v-observer="{ nextPage: nextPage }"
              class="observer"
              v-if="!loadMoreDisabled && !isSearchLoading"
            ></div>
          </VCard>
        </VCol>
      </VRow>
    </VSlideXReverseTransition>
    <VFadeTransition mode="out-in">
      <div v-if="isSearchLoading">
        <div class="search-loader__wrapper">
          <div class="search-loader__inner">
            <v-progress-circular indeterminate color="primary"> </v-progress-circular>
          </div>
        </div>
      </div>
    </VFadeTransition>

    <EditDescriptionDialog
      v-if="editDialog"
      :visible="editDialog"
      @close="editDialog = false"
      :description="editEditItem"
      :typeDialog="typeDialog"
    />
  </div>
</template>

<script>
import _ from "lodash";
import DescriptionItem from "./DescriptionItem.vue";
import depotService from "../../../services/request/depot/depotService";
import notifications from "../../../mixins/notifications";
import loader from "../../../mixins/loader";
import AddDescriptionDialog from "./AddDescriptionDialog.vue";
import EventBus from "../../../events/EventBus";
import chipTab from "@/components/common/chipTab.vue";
import EditDescriptionDialog from "./EditDescriptionDialog.vue"
import DescriptionRow from "./DescriptionsRow.vue";
import {
  validationForTitle
} from "@/mixins/helpers";
import {mapActions, mapGetters} from "vuex";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";

export default {
  name: "DescriptionsComponent",
  components: {
    AddDescriptionDialog,
    EditDescriptionDialog,
    DescriptionItem,
    chipTab,
    DescriptionRow,
  },
  mixins: [loader, notifications],
  data: () => ({
    editDialog: false,
    descriptions: [],
    createDialog: false,
    page: 0,
    perPage: 15,
    totalPages: 0,
    isLoadingFilter: false,
    activeCardID: "",
    searchString: "",
    oldSearchString: "",
    isSearchLoading: false,
    chipTabsItems: {},
    loadingChips: false,
    categoryChipTab: "",
    isResetChip: false,
    editEditItem: {},
    typeDialog: "add",
    viewType: 0,
    loadMoreDisabled: false,
    loadTime: 0
  }),
  async mounted() {
    this.checkViewList();
    this.getChipTabsItems();
    EventBus.$on("description-added", async item => {
      this.descriptions.unshift(item);
    });
    EventBus.$on("on-edit-dialog", ({ editDialog, item }) => {
      this.typeDialog = "edit";
      this.editDialog = editDialog;
      this.editEditItem = item;
    });
    EventBus.$on("description-updated", async item => {
      const newItem = this.descriptions.find(e => e.uuid === item.uuid);

      if (newItem.name !== item.name) {
        if (this.searchString) {
          this.onSearch();
        }
        if (this.categoryChipTab !== this.$t("btn.all")) {
          this.onClickChipTab(this.categoryChipTab);
        }
      }
      const itemIndex = this.descriptions.indexOf(
        this.descriptions.find(e => e.uuid === item.uuid)
      );
      this.descriptions.splice(itemIndex, 1, item);
      // await this.getDescriptions(false,false,true);
    });

    await this.getDescriptions();
    this.checkLoadTime();
  },

  methods: {
    validationForTitle,
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    async onClickChipTab(e) {
      this.categoryChipTab = e;
      this.loadingChips = true;

      if (e === this.$t("btn.all")) {
        if (this.searchString) {
          this.searchString = "";
        }
        await this.getDescriptions();
      } else {
        if (this.searchString) {
          this.searchString = "";
        }
        try {
          this.page = 0;
          this.isSearchLoading = true;
          const params = {
            name: e,
            uselike: true,
            offset: this.page * this.perPage,
            limit: this.perPage,
            order: "DESC",
            orderby: "time_created"
          };
          const newItems = await depotService.getProductsDescriptions(params);
          this.descriptions = newItems;
          setTimeout(() => {
            this.isSearchLoading = false;
          }, 500);
        } catch (e) {
          this.isSearchLoading = false;
          console.log(e)
        }
      }
      this.loadingChips = false;
    },

    async getChipTabsItems() {
      try {
        const params = {};
        const chipItems = await depotService.getItemsChipListCatalog(params);
        this.chipTabsItems = chipItems;
        if (this.chipTabsItems) {
          this.chipTabsItems = this.chipTabsItems.sort((a, b) => b.count - a.count);
        }
      } catch (e) {
        console.log(e)
      }
    },

    onSearch: _.debounce(async function() {
      if (this.searchString) {
        try {
          this.page = 0;
          this.isSearchLoading = true;
          const params = {
            name: this.searchString,
            uselike: true,
            offset: this.page * this.perPage,
            limit: this.perPage,
            order: "DESC",
            orderby: "time_created"
          };
          const newItems = await depotService.getProductsDescriptions(params);
          this.descriptions = newItems;
          setTimeout(() => {
            this.isSearchLoading = false;
          }, 500);
          this.oldSearchString = this.searchString;
        } catch (e) {
          this.isSearchLoading = false;
          console.log(e)
        }
      } else {
        this.categoryChipTab = this.$t("btn.all");
        this.page = 0;
        await this.getDescriptions();
      }
    }, 100),
    activeCard(e) {
      this.activeCardID = e;
    },
    async nextPage() {
      this.page += 1;
      await this.getDescriptions(true, true);
    },
    async onDeleteDescription(uuid) {
      try {
        this.setLoading();
        await depotService.deleteProductDescription(uuid);
        this.setLoading(false);
        this.setSuccessNotification(this.$t("catalog.item_desc_deleted_successfully"));
        await this.getDescriptions();
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    async getDescriptions(next = false, isLoadingLine, update = false) {
      try {
        if (isLoadingLine) {
          this.isLoadingFilter = true;
        } else {
          this.setLoading(true);
        }
        const params = {
          order: "DESC",
          orderby: "time_created"
        };
        if (this.searchString) {
          params.name = this.searchString;
          params.uselike = true;
        }
        if (this.categoryChipTab && this.categoryChipTab !== this.$t("btn.all")) {
          params.name = this.categoryChipTab;
          params.uselike = true;
        }
        params.offset = update ? 0 : this.page * this.perPage;
        params.limit = update ? this.largeLimit : this.perPage;
        const newItems = await depotService.getProductsDescriptions(params);
        //
        if (next) {
          this.descriptions.push(...newItems);
        } else {
          this.descriptions = newItems;
        }
        this.loadMoreDisabled = this.descriptions.length < this.perPage;
        //
        //
        if (isLoadingLine) {
          this.isLoadingFilter = false;
        } else {
          this.setLoading(false);
        }
      } catch (e) {
        if (isLoadingLine) {
          this.isLoadingFilter = false;
        } else {
          this.setLoading(false);
        }
        console.log(e)
      }
    },
    onChangeViewType(e) {
      if (e === 0) {
        window.localStorage.setItem("productsViewType", 0);
      } else {
        window.localStorage.setItem("productsViewType", 1);
      }
    },
    checkViewList() {
      const localListView = window.localStorage.getItem("productsViewType");
      if (localListView) {
        this.viewType = localListView == 0 ? 0 : 1;
      }
    }
  },
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
    largeLimit() {
      const pageLimit = this.perPage * this.page;
      if (pageLimit >= 1000) {
        this.page = this.pageBeforeLimit - 1;
        this.$scrollTop();
        return 1000;
      }
      return this.perPage * this.page;
    }
  },
  watch: {
    page(e) {
      if (this.perPage * this.page >= 1000 && this.perPage * this.page <= 1000 + this.perPage) {
        this.pageBeforeLimit = e;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.search {
  &-loader__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
  }
  &-loader__inner {
    position: absolute;
  }
}
</style>
