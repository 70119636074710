import { render, staticRenderFns } from "./DescriptionsComponent.vue?vue&type=template&id=f46b14a4&scoped=true&"
import script from "./DescriptionsComponent.vue?vue&type=script&lang=js&"
export * from "./DescriptionsComponent.vue?vue&type=script&lang=js&"
import style0 from "./DescriptionsComponent.vue?vue&type=style&index=0&id=f46b14a4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f46b14a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardTitle,VCol,VDivider,VFadeTransition,VIcon,VProgressCircular,VRow,VSimpleTable,VSlideXReverseTransition,VSlideXTransition,VSpacer,VTextField})
