<template>
  <DescriptionsComponent />
</template>

<script>
import DescriptionsComponent from '../components/products/descriptions/DescriptionsComponent.vue';

export default {
  name: 'Catalog',
  components: {
    DescriptionsComponent,
  },
};
</script>

<style scoped>

</style>
