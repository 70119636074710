<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    :transition="$dialogTransition"
  >
    <VCard>
      <VCardTitle>
        <span class="font-20 font-weight-bold">Додати продукт</span>
      </VCardTitle>
      <VDivider class="mb-2" />
      <VCardText class="pb-0">
        <VContainer class="py-0">
          <div class="text-center" v-if="loading">
            <VProgressCircular
              :size="50"
              color="primary"
              indeterminate
            ></VProgressCircular>
          </div>
          <div v-else>
            <VRow>
              <VCol cols="12" md="6" class="text-center justify-center">
                <label for="product_photo" class="text-black">
                  <div class="text-center d-flex justify-center">
                    <div
                      style="width: 280px; height: 150px; background-color: #eeeded;"
                      class="d-flex br-10 justify-center align-center photo-area"
                      v-if="!photoPreview"
                    >
                      <div>
                        <VIcon>mdi-plus</VIcon><br>
                        Додати фото
                      </div>
                    </div>
                    <img
                      v-else
                      :src="photoPreview"
                      alt=""
                      style="width: 250px; height: 120px;"
                      class="br-10"
                    >
                  </div>
                </label>
                <VBtn
                  v-if="photoPreview"
                  depressed
                  class="mt-1"
                  @click="desc.image = null;photoPreview = null;"
                >Видалити</VBtn>
                <VFileInput
                  accept="image/*"
                  class="d-none"
                  id="product_photo"
                  v-model="desc.image"
                  @change="makePreview"
                />
                <VTextField
                  class="mt-3"
                  outlined dense
                  label="Вar Code"
                  hide-details
                  v-model="desc.bar_code"
                  @keypress="validationForBarcode($event)"
                  @paste="validationForBarcode($event)"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="6" class="">
                <VRow class="py-0">
                  <VCol cols="12" class="mb-0">
                    <VTextField
                      outlined dense
                      :label="language.TITLE"
                      required
                      v-model="desc.name"
                      :hide-details="!nameErrors.length"
                      :error-messages="nameErrors"
                      @keypress="validationForTitle($event)"
                      @paste="validationForTitle($event)"
                    ></VTextField>
                  </VCol>
                  <VCol cols="12" class="mt-0">
                    <span class="font-14 font-weight-bold d-block mb-2">
                      Габарити
                    </span>
                    <VRow class="pa-0">
                      <VCol cols="12" md="4" sm="6">
                        <VTextField
                          outlined dense
                          type="number"
                          label="Ш"
                          min="0"
                          required
                          hide-details
                          v-model="desc.width"
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                        ></VTextField>
                      </VCol>
                      <VCol cols="12" md="4" sm="6">
                        <VTextField
                          outlined dense
                          type="number"
                          label="В"
                          min="0"
                          required
                          hide-details
                          v-model="desc.height"
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                        ></VTextField>
                      </VCol>
                      <VCol cols="12" md="4" sm="6">
                        <VTextField
                          outlined dense
                          type="number"
                          label="Д"
                          min="0"
                          required
                          hide-details
                          v-model="desc.length"
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                        ></VTextField>
                      </VCol>
                    </VRow>
                  </VCol>
                  <VCol cols="12" md="6" sm="6">
                    <VTextField
                      outlined dense
                      type="number"
                      min="0"
                      label="Ціна (грн)"
                      required
                      hide-details
                      v-model="desc.cost"
                      @keypress="validationForPrice($event)"
                      @paste="validationForPrice($event)"
                    ></VTextField>
                  </VCol>
                  <VCol cols="12" md="6" sm="6">
                    <VTextField
                      outlined dense
                      type="number"
                      label="Вага (кг)"
                      min="0"
                      required
                      hide-details
                      v-model="desc.weight_gross"
                      @keypress="validationForPrice($event)"
                      @paste="validationForPrice($event)"
                    ></VTextField>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12" style="max-height: 310px;" class="overflow-y-auto mb-7">
                <VueEditor
                  v-model="desc.description"
                />
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 container">
          <VCol cols="12" md="6" sm="12" class="my-0 py-0">
            <VBtn
              @click="visibility = false"
              block
              class="text-white text-transform-none br-10 w-100"
              dark
            >
              {{ language.CANCEL }}
            </VBtn>
          </VCol>
          <VCol cols="12" md="6" sm="12" class="my-0 py-0">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              dark
              block
              @click="onCreate"
            >
              {{ language.CREATE }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { VueEditor } from 'vue2-editor';
import notifications from '../../../mixins/notifications';
import depotService from '../../../services/request/depot/depotService';
import EventBus from '../../../events/EventBus';
import language from '../../../mixins/language';
import {
  validationForBarcode,
  validationForPrice,
  validationForTitle
} from "@/mixins/helpers";

export default {
  name: 'AddDescriptionDialog',
  components: {
    VueEditor,
  },
  mixins: [notifications, language, validationMixin],
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  validations: {
    desc: {
      name: { required },
      // code_bar: { required, numeric },
    },
  },
  data: () => ({
    loading: false,
    desc: {
      name: '',
      height: '',
      width: '',
      length: '',
      weight_gross: '',
      weight_net: '',
      cost: '',
      code_bar: '',
      description: '',
      image: null,
      bar_code: '',
    },
    photoPreview: '',
  }),
  methods: {
    validationForPrice,
    validationForBarcode,
    validationForTitle,
    // eslint-disable-next-line consistent-return
    isNumber(event) {
      const charCode = event.keyCode;
      if (event.key === '.') event.preventDefault();
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault();
      } else {
        return true;
      }
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('name', this.desc.name);
          formData.append('size_height', this.desc.height);
          formData.append('size_width', this.desc.width);
          formData.append('size_length', this.desc.length);
          formData.append('weight_gross', this.desc.weight_gross);
          formData.append('weight_net', this.desc.weight_net);
          formData.append('cost', this.desc.cost);
          formData.append('bar_code', this.desc.bar_code);
          formData.append('description', this.desc.description);
          if (this.desc.image) {
            formData.append('image', this.desc.image);
          }
          await depotService.addProductDescription(formData);
          this.setSuccessNotification('Продукт успішно додано!');
          this.loading = false;
          this.$v.$reset();
          this.clearDesc();
          this.visibility = false;
          EventBus.$emit('description-added');
        } catch (e) {
          this.loading = false;
          console.log(e)
        }
      }
    },
    clearDesc() {
      Object.keys(this.desc).forEach((key) => {
        this.desc[key] = null;
      });
    },
    makePreview() {
      let photoPreview = null;
      const { image } = this.desc;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = (ev) => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
      };
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.desc.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.desc.name.required
      && errors.push(this.language.isRequired(this.language.NAME));
      return errors;
    },
    nsCodeErrors() {
      const errors = [];
      if (!this.$v.desc.code_bar.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.desc.code_bar.required
      && errors.push(this.language.isRequired('Bar Code'));
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.desc.code_bar.numeric
      // && errors.push('Bar Code повинен складатися тільки з чисел!');
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.desc.code_bar.nsCodeMask
      // && errors.push(this.language.NS_CODE_VALIDATION);
      return errors;
    },
  },
};
</script>

<style scoped>
.photo-area:hover {
  cursor: pointer;
  opacity: 0.7;
}
</style>
