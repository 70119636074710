<template>
  <VDialog
    v-model="visibility"
    max-width="650px"
    @click:outside="showConfirmDialog"
    :persistent="!btnDiabled"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    :scrollable="
      $vuetify.breakpoint.smAndDown || $vuetify.breakpoint.height < 800 || Boolean(photoPreview)
    "
  >
    <VCard height="auto">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="headline" :class="{ 'text-body-1': $vuetify.breakpoint.smAndDown }">{{
          typeDialog === "add" ? $t("catalog.add_descTitle") : $t("catalog.edit_descTitle")
        }}</span>
        <v-spacer />
      </VCardTitle>
      <VCardText class="pb-0">
        <VContainer class="py-1">
          <div class="text-center" v-if="loading">
            <VProgressCircular :size="50" color="primary" indeterminate></VProgressCircular>
          </div>
          <div v-else>
            <VRow>
              <VCol
                cols="12"
                md="5"
                class="text-center justify-center d-flex"
                style="flex-direction:column"
              >
                <VTextField
                  outlined
                  dense
                  :label="$t('table.bar_code')"
                  hide-details
                  v-model="desc.bar_code"
                  :maxLength="20"
                  color="success"
                  @keypress="validationForBarcode($event)"
                  @paste="validationForBarcode($event)"
                ></VTextField>
                <label for="product_photo_prev" class="text-black">
                  <div class="text-center d-flex justify-center">
                    <div
                      style="width: 100%; height: 150px"
                      class="
                        d-flex
                        br-10
                        justify-center
                        align-center
                        photo-area
                      "
                      :style="$vuetify.breakpoint.mdAndDown ? 'margin-top:10px;' : ''"
                      :class="{
                        'background-img': !photoPreview && !editedDescription.path_img
                      }"
                    >
                      <div
                        v-if="desc.path_img && !photoPreview"
                        class="br-10"
                        style="width: 100%; height: 150px;border:1px solid #e4e4e4"
                      >
                        <img
                          v-show="isImgLoaded"
                          @load="showImg"
                          :src="editedDescription.path_img"
                          class="mb-0 pb-0 br-10 ma-auto  h-100"
                          style="width:100%; object-fit: contain;"
                        />
                        <template v-if="editedDescription.path_img && !isImgLoaded">
                          <VRow class="fill-height ma-0" align="center" justify="center">
                            <VProgressCircular indeterminate color="green "></VProgressCircular>
                          </VRow>
                        </template>
                      </div>
                      <img
                        :src="photoPreview"
                        style="width: 250px; height: 120px; object-fit: contain"
                        alt=""
                        v-else-if="photoPreview"
                      />

                      <div v-else>
                        <VIcon>mdi-plus</VIcon>
                        <br />
                        {{ $t("catalog.add_photo") }}
                      </div>
                    </div>
                  </div>
                </label>
                <VBtn
                  class="mt-1"
                  v-if="photoPreview"
                  @click="
                    photoPreview = null;
                    image = null;
                  "
                  depressed
                  >{{ $t("btn.delete") }}
                </VBtn>
                <VFileInput
                  class="d-none"
                  id="product_photo_prev"
                  accept="image/*"
                  v-model="image"
                  @change="makePreview"
                />
              </VCol>
              <VCol cols="12" md="7" sm="12" class="">
                <VRow class="py-0">
                  <VCol cols="12" class="mb-0">
                    <VTextField
                      outlined
                      dense
                      :label="$t('form.title')"
                      required
                      v-model="desc.name"
                      :hide-details="!nameErrors.length"
                      :error-messages="nameErrors"
                      :maxLength="30"
                      color="success"
                      @keypress="validationForTitle($event)"
                      @paste="validationForTitle($event)"
                    />
                  </VCol>
                  <VCol cols="12" class="mt-0">
                    <VRow align="center" justify="space-between" no-gutters>
                      <VRow no-gutters>
                        <span class="font-14 font-weight-bold d-block mr-1">
                          {{ $t("catalog.dimensions") }}
                        </span>
                        <toggle-button
                          :sync="true"
                          v-model="metricsSwitch"
                          :labels="{ checked: $t('catalog.mm'), unchecked: $t('catalog.sm') }"
                        />
                      </VRow>
                    </VRow>
                    <VRow class="mt-1">
                      <VCol cols="12" md="4" sm="6">
                        <VTextField
                          outlined
                          dense
                          :label="$t('catalog.widthAbbreviation')"
                          required
                          min="1"
                          v-model="desc.size_width"
                          hide-details
                          :error-messages="WidthErrors"
                          ref="size_width"
                          color="success"
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                        />
                      </VCol>
                      <VCol cols="12" md="4" sm="6">
                        <VTextField
                          outlined
                          dense
                          :label="$t('catalog.heightAbbreviation')"
                          required
                          min="1"
                          v-model="desc.size_height"
                          hide-details
                          :error-messages="HeightErrors"
                          color="success"
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                        />
                      </VCol>
                      <VCol cols="12" md="4" sm="6">
                        <VTextField
                          outlined
                          dense
                          :label="$t('catalog.lengthAbbreviation')"
                          required
                          min="1"
                          v-model="desc.size_length"
                          hide-details
                          :error-messages="LengthErrors"
                          ref="numberInput"
                          color="success"
                          @keypress="validationForPrice($event)"
                          @paste="validationForPrice($event)"
                        />
                      </VCol>
                    </VRow>
                    <VRow
                      v-if="
                        LengthErrors.length > 0 || WidthErrors.length > 0 || HeightErrors.length > 0
                      "
                    >
                      <VCol>
                        <span class="info-text">
                          <div v-if="metricsSwitch">
                            {{ $t("catalog.dimensionsMMerrors") }}
                          </div>
                          <div v-else>
                            {{ $t("catalog.dimensionsSMerrors") }}
                          </div>
                        </span>
                      </VCol>
                    </VRow>
                  </VCol>
                  <VCol cols="12" md="6" sm="6">
                    <VTextField
                      outlined
                      dense
                      :label="$t('catalog.price')"
                      required
                      hide-details
                      min="0"
                      v-model="desc.cost"
                      color="success"
                      @keypress="validationForPrice($event)"
                      @paste="validationForPrice($event)"
                      :maxlength="9"
                    ></VTextField>
                  </VCol>
                  <VCol cols="12" md="6" sm="6">
                    <VTextField
                      outlined
                      dense
                      :label="$t('catalog.weight_netto')"
                      required
                      hide-details
                      min="0"
                      v-model="desc.weight_net"
                      color="success"
                      @keypress="validationForPrice($event)"
                      @paste="validationForPrice($event)"
                      :maxlength="5"
                    ></VTextField>
                  </VCol>
                </VRow>
              </VCol>
              <div class="d-flex justify-content-space-between w-100">
                <VCol v-if="typeDialog === 'edit'" cols="12" md="5">
                  <VBtn block color="grey darken-1" outlined @click="visibleAddOption = true">
                    {{ $t("catalog.add_new_option") }}
                  </VBtn>
                </VCol>
                <VCol cols="12" md="5" class="py-0" v-if="optionList.length">
                  <div class="option-list__wrapper">
                    <VRow
                      class=" pa-0 mt-1 ma-0 justify-space-between "
                      v-for="option in optionList"
                      :key="option.id"
                    >
                      <span>{{ option.name }}</span>
                      <span style="color: green">({{ option.amount }})</span>
                    </VRow>
                  </div>
                </VCol>
              </div>
              <VCol cols="12" style="max-height: 310px;" class="overflow-y-auto mb-7">
                <VueEditor ref="editor" v-model="desc.description" @text-change="editorMaxLength" />
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 container">
          <VCol cols="6" class="my-0 py-0">
            <VBtn
              @click="onClose"
              block
              class="text-white text-transform-none br-10 w-100"
              dark
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol cols="6" class="my-0 py-0">
            <VBtn
              @click="onOk"
              block
              :disabled="btnDiabled"
              class="success-bg text-white text-transform-none br-10 w-100"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ typeDialog === "add" ? $t("btn.add") : $t("btn.update") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
    <add-option-dialog
      :visible="visibleAddOption"
      @close="visibleAddOption = false"
      :uuid_parent="editedDescription.uuid"
      @updateOptions="updateOptions"
    />
  </VDialog>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, between } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
// import nsCodeMask from '../../../validators/nsCodeMask';
import language from "../../../mixins/language";
import user from "../../../mixins/user";
import notifications from "../../../mixins/notifications";
import loader from "../../../mixins/loader";
import depotService from "../../../services/request/depot/depotService";
import EventBus from "../../../events/EventBus";
import onlyNumber from "../../../mixins/onlyNumber";
import AddOptionDialog from "./AddOptionDialog.vue";
import {
  noEngText, validationForTitle, validationForBarcode, validationForPrice
} from "@/mixins/helpers";
import fileSizeValidation from "@/validators/fileSizeProduct";

export default {
  name: "EditDescriptionDialog",
  components: {
    VueEditor,
    ConfirmLeftDialog,
    AddOptionDialog
  },
  validations() {
    if (this.metricsSwitch) {
      return {
        image: { fileSizeValidation },
        desc: {
          name: { required },
          size_height: {
            required,
            between: between(1, 2000)
          },
          size_length: {
            required,
            between: between(1, 2000)
          },
          size_width: {
            required,
            between: between(1, 2000)
          }
          // code_bar: { required, numeric },
        }
      };
    } else {
      return {
        image: { fileSizeValidation },
        desc: {
          name: { required },
          size_height: {
            required,
            between: between(0.1, 200)
          },
          size_length: {
            required,
            between: between(0.1, 200)
          },
          size_width: {
            required,
            between: between(0.1, 200)
          }
          // code_bar: { required, numeric },
        }
      };
    }
    
  },
  props: {
    typeDialog: {
      requied: true
    },
    visible: {
      required: true,
      type: Boolean
    },
    description: {
      required: true
    }
  },
  methods: {
    validationForPrice,
    validationForBarcode,
    validationForTitle,
    noEngText,
    editorMaxLength() {
      const quill = this.$refs.editor.quill;
      const len = quill.getLength();
      if (len > 256) {
        quill.deleteText(256, len);
      }
    },
    onOk() {
      if (this.typeDialog === "add") {
        this.onCreate();
      } else {
        this.onUpdate();
      }
    },
    showConfirmDialog() {
      if (!this.btnDiabled) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    showImg() {
      this.isImgLoaded = true;
    },
    descriptionList() {
      // orderDialog(this.optionList);
      if (this.editedDescription.options_details) {
        this.optionList = this.editedDescription.options_details;
      }
    },
    // eslint-disable-next-line consistent-return
    // isNumber(event) {
    //   const charCode = event.keyCode;
    //   if (event.key === ".") event.preventDefault();
    //   if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    //     event.preventDefault();
    //   } else {
    //     return true;
    //   }
    // },
    onClose() {
      this.$v.$reset();
      this.visibility = false;
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.setLoading(true);
          const formData = new FormData();
          formData.append("uuid", this.editedDescription.uuid);
          const changedData = this.$getChangedData(this.desc, this.editedDescription);
          changedData.forEach(data => {
            if (data.field == "path_img") {
              formData.append("image", data.value);
            } else {
              formData.append(data.field, data.value);
            }
          });
          formData.append(
            "size_height",
            !this.metricsSwitch ? this.desc.size_height * 10 : this.desc.size_height
          );
          formData.append(
            "size_width",
            !this.metricsSwitch ? this.desc.size_width * 10 : this.desc.size_width
          );
          formData.append(
            "size_length",
            !this.metricsSwitch ? this.desc.size_length * 10 : this.desc.size_length
          );
          this.$v.$reset();
          const item = await depotService.updateProductDescription(formData);
          this.setSuccessNotification(this.$t("catalog.item_update_successfully"));
          this.setLoading(false);
          EventBus.$emit("description-updated", item.data.object);
          this.visibility = false;
        } catch (e) {
          this.setLoading(false);
          console.log(e)
        }
      }
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.setLoading(true);
          const formData = new FormData();

          // if (!this.metricsSwitch && this.editedDescription.size_height != this.desc.size_height) {
          //   this.desc.size_height *= 10;
          // }
          // if (!this.metricsSwitch && this.editedDescription.size_width != this.desc.size_width) {
          //   this.desc.size_width *= 10;
          // }
          // if (!this.metricsSwitch && this.editedDescription.size_length != this.desc.size_length) {
          //   this.desc.size_length *= 10;
          // }

          // const changedData = this.$getChangedData(this.desc, this.description);
          formData.append("name", this.desc.name);
          formData.append(
            "size_height",
            !this.metricsSwitch
              ? this.desc.size_height * 10
              : this.desc.size_height
          );
          formData.append(
            "size_width",
            !this.metricsSwitch
              ? this.desc.size_width * 10
              : this.desc.size_width
          );
          formData.append(
            "size_length",
            !this.metricsSwitch
              ? this.desc.size_length * 10
              : this.desc.size_length
          );
          // formData.append("weight_gross", this.desc.weight_gross);
          if (this.desc.weight_net) {
            formData.append("weight_net", this.desc.weight_net);
          }
          if (this.desc.cost) {
            formData.append("cost", this.desc.cost);
          }
          if (this.desc.bar_code) {
            formData.append("bar_code", this.desc.bar_code);
          }
          if (this.desc.description) {
            formData.append("description", this.desc.description);
          }

          if (this.desc.path_img) {
            formData.append("image", this.desc.path_img);
          }
          // changedData.forEach(data => {
          //   if (data.field == 'path_img') {
          //     formData.append('image', data.value);
          //   }
          //   else {
          //     formData.append(data.field, data.value);
          //   }
          // });
          this.$v.$reset();
          const item = await depotService.addProductDescription(formData);
          this.setSuccessNotification(this.$t("catalog.item_add_successfully"));
          this.setLoading(false);
          EventBus.$emit("description-added", item.data.object);
          // this.desc = {};
          this.visibility = false;
        } catch (e) {
          this.setLoading(false);
          this.setErrorNotification(e.error.description);
        }
      }
    },
    clearDesc() {
      Object.keys(this.desc).forEach(key => {
        this.desc[key] = "";
      });
    },
    makePreview() {
      if(this.$v.image.fileSizeValidation){
      let photoPreview = null;
      const reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onloadend = ev => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
      };
    } else{
        this.image = null;
        this.photoPreview = null;
        this.setErrorNotification(this.$t("form.errors.photoMaxSizeProduct"));
    }
    },
    // eslint-disable-next-line
    updateOptions({ amount = 0, description, name, uuid }) {
      const obj = {
        amount: amount,
        description: description,
        name: name,
        uuid: uuid
      };
      this.optionList.push(obj);
      this.editedDescription.options_details = this.optionList;
      EventBus.$emit("description-updated", this.editedDescription);
    },
  },
  mixins: [notifications, language, user, validationMixin, loader, onlyNumber],
  watch: {
    desc: {
      deep: true,
      handler(e) {
        if (this.desc.size_height || this.desc.size_length || this.desc.size_width) {
          this.desc.size_height = +this.desc.size_height;
          this.desc.size_width = +this.desc.size_width;
          this.desc.size_length = +this.desc.size_length;
        }
        if (Number.isNaN(this.desc.size_height)) {
          this.desc.size_height = 0;
        }
        if (Number.isNaN(this.desc.size_length)) {
          this.desc.size_length = 0;
        }
        if (Number.isNaN(this.desc.size_width)) {
          this.desc.size_width = 0;
        }
        if (e.weight_net === "") {
          this.desc.weight_net = null;
        }
        this.desc.description && (this.desc.description = this.desc.description.replace(/<\/?[^>]+>/gi, ''));
        this.editedDescription.description && (this.editedDescription.description = this.editedDescription.description.replace(/<\/?[^>]+>/gi, ''));
        this.btnDiabled = _.isEqual(this.editedDescription, this.desc);
      }
    },
    image: {
      deep: true,
      handler() {
        if (this.image) {
          this.desc.path_img = this.image;
        } else {
          this.desc.path_img = this.editedDescription.path_img;
        }
        this.desc.description && (this.desc.description = this.desc.description.replace(/<\/?[^>]+>/gi, ''));
        this.editedDescription.description && (this.editedDescription.description = this.editedDescription.description.replace(/<\/?[^>]+>/gi, ''));
        this.btnDiabled = _.isEqual(this.editedDescription, this.desc);
      }
    },
    $vuetify: {
      deep: true,
      handler(e) {
        //
      }
    },
    metricsSwitch: {
      deep: true,
      handler(e) {
        if (!e) {
          window.localStorage.setItem("metricParam", 0);
          this.desc.size_height /= 10;
          this.desc.size_width /= 10;
          this.desc.size_length /= 10;
          this.editedDescription.size_height /= 10;
          this.editedDescription.size_width /= 10;
          this.editedDescription.size_length /= 10;
        } else {
          window.localStorage.setItem("metricParam", 1);
          Math.ceil((this.desc.size_height *= 10));
          Math.ceil((this.desc.size_width *= 10));
          Math.ceil((this.desc.size_length *= 10));
          Math.ceil((this.editedDescription.size_height *= 10));
          Math.ceil((this.editedDescription.size_width *= 10));
          Math.ceil((this.editedDescription.size_length *= 10));
        }
        //
      }
    }
  },
  data: () => ({
    limit: 250,
    metricsSwitch: false,
    loading: false,
    photoPreview: null,
    isImgLoaded: false,
    btnDiabled: false,
    visibleConfirm: false,
    desc: {
      size_length: 1,
      size_height: 1,
      size_width: 1
    },
    editedDescription: {
      size_length: 1,
      size_height: 1,
      size_width: 1
    },
    optionList: [],
    image: null,
    visibleAddOption: false
  }),
  mounted() {
    const metricParam = window.localStorage.getItem("metricParam");
    this.editedDescription = {
      ...this.description
    }
    if (this.typeDialog === "add") {
      this.editedDescription = {
        name: "",
        bar_code: "",
        path_img: "",
        cost: "",
        weight_net: null,
        description: "",
        size_height: 1,
        size_width: 1,
        size_length: 1
      };
      this.desc = {
        ...this.editedDescription
      };
    }
    if (this.typeDialog === "edit") {
      this.editedDescription.size_height = +this.editedDescription.size_height || 1;
      this.editedDescription.size_width = +this.editedDescription.size_width || 1;
      this.editedDescription.size_length = +this.editedDescription.size_length || 1;

      this.desc = {
        ...this.editedDescription
      };
      this.descriptionList();
      // this.updateButtonDisabled();
      if (metricParam) {
        if (metricParam == 1) {
          this.metricsSwitch = true;
        }
        this.desc.size_height /= 10;
        this.desc.size_width /= 10;
        this.desc.size_length /= 10;
        this.editedDescription.size_height /= 10;
        this.editedDescription.size_width /= 10;
        this.editedDescription.size_length /= 10;
      }
    }
  },
  computed: {
    visibility: {
      get() {
        if (this.typeDialog === "add") {
          this.desc = {
            size_length: 1,
            size_height: 1,
            size_width: 1
          };
        }
        return this.visible;
      },
      set() {
        this.$emit("close");
        this.desc = {};
        if (!this.metricsSwitch) {
          this.desc.size_height *= 10;
          this.desc.size_width *= 10;
          this.desc.size_length *= 10;
          this.editedDescription.size_height *= 10;
          this.editedDescription.size_width *= 10;
          this.editedDescription.size_length *= 10;
        }
      }
    },
    getHeight() {
      return this.desc.size_height;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.desc.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.desc.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    LengthErrors() {
      const errors = [];
      if (!this.$v.desc.size_length.$dirty) {
        return errors;
      }
      if (!this.$v.desc.size_length.required) {
        errors.push("");
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions

      !this.$v.desc.size_length.between && errors.push("");
      return errors;
    },
    HeightErrors() {
      const errors = [];
      if (!this.$v.desc.size_height.$dirty) {
        return errors;
      }
      if (!this.$v.desc.size_height.required) {
        errors.push("");
        return errors;
      } // eslint-disable-next-line no-unused-expressions

      !this.$v.desc.size_height.between && errors.push("");
      return errors;
    },
    WidthErrors() {
      const errors = [];
      if (!this.$v.desc.size_width.$dirty) {
        return errors;
      }
      if (!this.$v.desc.size_width.required) {
        errors.push("");
        return errors;
      } // eslint-disable-next-line no-unused-expressions

      !this.$v.desc.size_width.between && errors.push("");
      return errors;
    },
    nsCodeErrors() {
      const errors = [];
      // if (!this.$v.desc.code_bar.$dirty) {
      //   return errors;
      // }
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.desc.code_bar.required
      // && errors.push(this.language.isRequired('Bar Code'));
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.desc.code_bar.numeric
      // && errors.push('Bar Code повинен складатися тільки з чисел!');
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.desc.code_bar.nsCodeMask
      // && errors.push(this.language.NS_CODE_VALIDATION);
      return errors;
    }
  }
};
</script>

<style scoped lang="scss">
.photo-area:hover {
  cursor: pointer;
  opacity: 0.7;
}

.background-img {
  background-color: #eeeded;
}
.option-list__wrapper {
  margin-top: 10px;
  max-height: 80px;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px; /* ширина для вертикального скролла */
    background-color: transparent;
  }
  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    border: 1px solid rgb(245, 245, 244);
    border-radius: 9em;
  }
}
</style>
